import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-576ee748"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("a", {
      class: "advertise-banner",
      href: _ctx.bannerUrl,
      target: "_blank",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.statistFlocktoryClickEvent && _ctx.statistFlocktoryClickEvent(...args)))
    }, null, 8, _hoisted_1)
  ]))
}