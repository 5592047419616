import { Lang } from "./contracts/LangEnum";
import { DomainConfiguration } from "./runtime-configuration/DomainConfiguration";

export const domainConfigurationMock: DomainConfiguration = {
  defaultLanguage: Lang.Ru,
  privacyPolice: undefined,
  bayonetApiKey: undefined,
  showTransactionDetails: true,
  isCustomMXTranslation: true,
  giftBannerRedirectUrl:
    "https://share.flocktory.com/exchange/login?ssid=4713&bid=8660",
  api: {
    host: "http://127.0.0.1:9700/api",
    cpHost: "https://api-preprod.cloudpayments.ru",
  },
  order: {
    isProd: true,
  },
  amountSettings: {
    firstDelimiter: "$& ",
    secondDelimiter: ",",
  },
  statistSettings: {
    appName: "cloud.payments.widget",
    enabled: true,
  },
  amplitudeSettings: {
    apiKey: "test",
  },
  payments: {
    masterPass: {
      masterPassUrl: "https://wallet.masterpass.ru",
      masterPassTestUrl: "https://testwallet.masterpass.ru",
    },
    googlePay: {
      environment: "TEST",
      settings: {
        gateway: "cloudpayments",
        merchantId: "BCR2DN4TZDCOVPLJ",
        merchantName: "CloudPayments",
      },
    },
    yandexPay: {
      merchantId: "43d5cd28-936e-4a33-a98e-ac9d8589b90e",
      paymentEnv: "SANDBOX",
    },
    installmentMX: {
      availableCardCategory: "",
      availableCardOrigin: "",
      availableBanks: [
        "Banorte",
        "HSBC México",
        "Santander México",
        "Scotiabank México",
      ],
    },
  },
  publicKey: {
    version: 13,
    pem: "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAudCbcgKMMUYGYTUuoIb3ej516B1Qb68CUFbfYMhllsbHKRtggTA46K5qA2SQsP7UUiCFUqJzDnLWkXbA6lWnHTSdU6DN+Q1mXCnUxRZYlMs3GB3Ff2k7cJrYnUUrr5Q+MC4UTTUDpMVqrA225syymIYowHTepdKSPSQ81Nc83MCS7Cv0fYHoCeQiarHIxSi98srtHuWJzvrO8Z5ncwM6Quf1sMh0TC2buzlAIwy2D+aSY/o7BlB0iFy5oIbUJvuiTwmK4M9uV8Bq6nmYj0DjT6fOQtQBxSOEuMGEi6ZuzC/xzEeIcKhAzECp8kr0f7hiavM4hDEUZeYvzA9AmN1dlwIDAQAB-----END PUBLIC KEY-----",
  },
  components: [
    {
      name: "SecuredIcon",
      path: "SecuredByCloud",
    },
  ],
  renewalSubscriptionPaymentMethods: [0, 1, 6],
  isTinkoffTcsNewFlow: true,
  fCardMinSum: {
    RUB: 120,
    EUR: 1,
  },
};
