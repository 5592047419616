export enum AmplitudeEvents {
  OrdersOpen = "orders_open",
  InsertCardData = "orders_insert_card_data",
  Receipt = "orders_receipt",
  SendReceipt = "orders_send_receipt",
  Pay = "orders_pay",
  PayCard = "orders_pay_card",
  PayYaPay = "orders_pay_yapay",
  PayApplePay = "orders_pay_applepay",
  PayGooglePay = "orders_pay_googlepay",
  PayTinkoffPay = "orders_pay_tinkoffpay",
  PaySpei = "orders_pay_spei",
  PayDolyame = "orders_pay_dolyame",
  PayLater = "orders_pay_later",
  PayDifferent = "orders_different_pay",
  PayCash = "orders_pay_cash",
  PayCashOxxo = "orders_pay_cash_oxxo",
  PayCashPharmacy = "orders_pay_cash_pharmacy",
  PayCashCStore = "orders_pay_cash_cstore",
  PayCashOpenBarcode = "orders_pay_cash_open_barcode",
  ThreeDs = "orders_3ds",
  OnSuccessPage = "orders_success_page",
  OnErrorPage = "orders_error_page",
  ShowQR = "orders_show_qr",
  WaitScreen = "widget_waiting",
  ChangeMethod = "orders_change_method",
  SaveCardOn = "click_save_card_on",
  SaveCardOff = "click_save_card_off",
  SaveCardHint = "save_card_hint",
  ForceSaveCardHint = "forced_save_hint",
  SentPaymentDetails = "sent_payment_details",
  OrderFlocktoryClick = "order_flocktory_click",
}
