<script lang="ts">
import { defineComponent, inject, onBeforeMount, ref } from "vue";
import { MonitoringServiceKey } from "@src/injections";
import { AppService } from "@src/services/AppService";
import { AmplitudeEvents } from "@src/contracts/AmplitudeEvents";

export default defineComponent({
  name: "AdvertiseBanner",
  props: {
    bannerUrl: {
      type: String,
      required: true,
    },
    paymentMethodStatist: {
      type: String,
    },
  },
  setup(props) {
    const monitoring = inject(MonitoringServiceKey);
    const appService = inject<AppService>("AppService")!;
    let statistFlocktoryClickEvent = ref();

    onBeforeMount(async () => {
      statistFlocktoryClickEvent.value = () => {
        monitoring
          ?.sendStatistEvent("events.clicks.floctory", {
            publicId: appService?.getOrderOptions()?.terminalPublicId,
            method: props.paymentMethodStatist as any,
            methods: [],
          })
          .sendAmplitudeEvent(AmplitudeEvents.OrderFlocktoryClick);
      };
    });

    return {
      statistFlocktoryClickEvent,
    };
  },
});
</script>

<template>
  <div>
    <a
      class="advertise-banner"
      :href="bannerUrl"
      target="_blank"
      @click="statistFlocktoryClickEvent"
    ></a>
  </div>
</template>

<style scoped lang="scss">
.advertise-banner {
  background: url("../../public/assets/advertise-banner.svg") center no-repeat;
  height: 196px;
  width: 100%;
  display: flex;
  background-size: cover;
  cursor: pointer;
  text-decoration: none;
  border-radius: 8px;

  @include mobile {
    background: url("../../public/assets/advertise-banner-mobile.svg") center
      no-repeat;
    background-size: 100%;
  }
}
</style>
