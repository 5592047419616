import {
  AppClient,
  OrdersAPI_DTO_API_Requests_GetQrStatusRequest,
} from "@src/services/api";
import {
  QRResponse,
  QRStatusCode,
  QRStatusResponse,
} from "@cloudpayments/api-client";
import { TransactionResultStatus } from "@src/contracts/TransactionResultStatus";
import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";

export class SomApi {
  private readonly appClient: AppClient;
  constructor(public readonly runtimeConfiguration: DomainConfiguration) {
    this.appClient = new AppClient({
      BASE: this.runtimeConfiguration.api.host,
    });
  }

  public async waitSomStatus(
    request: OrdersAPI_DTO_API_Requests_GetQrStatusRequest
  ): Promise<TransactionResultStatus> {
    const waitPaymentStatusResponse: QRResponse<QRStatusResponse> =
      await this.appClient.order.postOrderWaitQrStatus(request);

    switch (waitPaymentStatusResponse.Model.StatusCode) {
      case QRStatusCode.Completed:
      case QRStatusCode.Authorized:
        return TransactionResultStatus.Success;
      case QRStatusCode.Cancelled:
      case QRStatusCode.Declined:
        return TransactionResultStatus.Fail;
      case QRStatusCode.Created:
      case QRStatusCode.Pending:
      default:
        return TransactionResultStatus.Wait;
    }
  }
}
